import React, { Suspense, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import LoginPage from "../pages/LoginPage";
import Navbar from "./Navbar";
import { useAccount, useSignMessage, useDisconnect } from "wagmi";
import { getEncryptedAuthToken } from "../pureFunctions";
import { signIn } from "../gql/gql";

type Props = {
  children: React.ReactNode;
  // Navbar: React.ReactNode;
};

const Layout = (props: Props) => {
  const { children } = props;
  const classes = useStyles();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const { address, isConnected } = useAccount();

  const { signMessageAsync } = useSignMessage({
    message: "Please sign this message to connect to Ninfa.",
  });

  const { disconnect } = useDisconnect();

  useEffect(() => {
    const handleLogin = async () => {
      if (!address) return;
      const storedSig = localStorage.getItem("signature");
      var token: string;
      if (storedSig) {
        token = getEncryptedAuthToken(storedSig);
      } else {
        const sig = await signMessageAsync();
        token = getEncryptedAuthToken(sig);
        localStorage.setItem("signature", sig);
      }
      const admin = await signIn(address, token);
      if (!admin || admin.userType !== "admin") {
        setIsLoggedIn(false);
        disconnect();
        return;
      }
      setIsLoggedIn(true);
    };
    if (isConnected) {
      handleLogin();
      return;
    }
    setIsLoggedIn(false);
    disconnect();
  }, [isConnected, address, signMessageAsync, disconnect]);

  return (
    <div className={classes.maincontainer}>
      <Navbar isLoggedIn={isLoggedIn} address={address || "unknown"} />
      {isLoggedIn ? (
        <Suspense fallback={<div>Loading...</div>}>
          <div className={classes.root}>
            <div className={classes.container}>{children}</div>
          </div>
        </Suspense>
      ) : (
        <LoginPage />
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  maincontainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    // position: "relative",
    background: "#000",
    color: "#fff",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth: "1920px",
    padding: "0 2rem",
  },
}));
export default Layout;
