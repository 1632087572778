import React, { lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Layout from "./components/Layout";

const Home = lazy(
  () => import("./pages/Home/Home" /* webpackChunkName: "home" */)
);
const Graphiql = lazy(
  () => import("./pages/Graphiql/Graphiql" /* webpackChunkName: "home" */)
);
const Users = lazy(
  () => import("./pages/Users/Users" /* webpackChunkName: "home" */)
);
const Artworks = lazy(
  () => import("./pages/Artworks/Artworks" /* webpackChunkName: "home" */)
);
const Apply = lazy(
  () => import("./pages/Apply/Apply" /* webpackChunkName: "home" */)
);
const Selections = lazy(
  () => import("./pages/Selections/Selections" /* webpackChunkName: "home" */)
);
const Invoice = lazy(
  () => import("./pages/Invoice/Invoice" /* webpackChunkName: "home" */)
);
const Charts = lazy(
  () => import("./pages/Charts/Charts" /* webpackChunkName: "home" */)
);
const SelectionDetail = lazy(
  () =>
    import("./pages/Selections/SelectionDetail" /* webpackChunkName: "home" */)
);
const Sommelier = lazy(
  () => import("./pages/Newsletter/Sommelier" /* webpackChunkName: "home" */)
);
const Interviews = lazy(
  () => import("./pages/Interviews/Interviews" /* webpackChunkName: "home" */)
);
const InterviewDetail = lazy(
  () =>
    import("./pages/Interviews/InterviewDetail" /* webpackChunkName: "home" */)
);

const Routers = ({ children }: { children?: React.ReactNode }) => (
  <Router>
    {children}
    <Switch>
      <Route exact path="/">
        <Layout>
          <Home />
        </Layout>
      </Route>
      <Route exact path="/graph">
        <Layout>
          <Graphiql />
        </Layout>
      </Route>
      <Route exact path="/users">
        <Layout>
          <Users />
        </Layout>
      </Route>
      <Route exact path="/art">
        <Layout>
          <Artworks />
        </Layout>
      </Route>
      <Route exact path="/apply">
        <Layout>
          <Apply />
        </Layout>
      </Route>
      <Route exact path="/selections">
        <Layout>
          <Selections />
        </Layout>
      </Route>
      <Route exact path="/interviews">
        <Layout>
          <Interviews />
        </Layout>
      </Route>
      <Route exact path="/invoice">
        <Layout>
          <Invoice />
        </Layout>
      </Route>
      <Route exact path="/charts">
        <Layout>
          <Charts />
        </Layout>
      </Route>
      <Route exact path="/selection/*">
        <Layout>
          <SelectionDetail />
        </Layout>
      </Route>
      <Route exact path="/interview/*">
        <Layout>
          <InterviewDetail />
        </Layout>
      </Route>
      <Route exact path="/sommelier">
        <Layout>
          <Sommelier />
        </Layout>
      </Route>
    </Switch>
  </Router>
);
export default Routers;

// const t = `<Route exact path="/referral/*/:code">
// <DarkLayout Navbar={HomeNavbar}>
//   <ApplyWithReferral />
// </DarkLayout>
// </Route>`
