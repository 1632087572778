import React from "react";

import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { WagmiConfig } from "wagmi";
import { mainnet, arbitrum } from "viem/chains";
import Routers from "./Routes";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "ddb471e501d5b6a63b9c6fe263242a27";

// 2. Create wagmiConfig
const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [mainnet, arbitrum];
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains });

const App = () => (
  <WagmiConfig config={wagmiConfig}>
    <ThemeProvider theme={darkTheme}>
      <Routers />
    </ThemeProvider>
  </WagmiConfig>
);

export default App;
