import { GraphQLClient } from "graphql-request";
import {
  // CreateInterviewMutationVariables,
  GetApplicationsQueryVariables,
  // UpdateCuratedSelectionMutationVariables,
  // UpdateInterviewMutationVariables,
  getSdk,
} from "./gen-types";

// export const BASE_API_URL = "http://localhost:8001";
export const BASE_API_URL = "https://api.ninfa.io";

export const GQL_URL = `${BASE_API_URL}/v1/graphql`;
const client = getSdk(new GraphQLClient(GQL_URL));

export const signIn = async (walletAddress: string, token: string) => {
  const res = await client.SignIn(
    { walletAddress },
    {
      token: token,
      address: walletAddress,
    }
  );
  return res.user;
};

export const searchUsers = async (search: string) => {
  const res = await client.SearchUsers({ search });
  return res.users;
};

export const getArtists = async () => {
  const res = await client.GetArtists();
  return res.users;
};

export const searchArtworks = async (search: string) => {
  const res = await client.SearchArtworks({ search });
  return res.searchNfts;
};

export const updateUserType = async (
  id: number,
  userType: string,
  walletAddress: string,
  token: string
) => {
  const res = await client.UpdateUserType(
    { id, userType },
    {
      token: token,
      address: walletAddress,
    }
  );
  return res.updateUserType;
};

export const setHideArtwork = async (
  id: number,
  hidden: boolean,
  walletAddress: string,
  token: string
) => {
  const res = await client.HideNft(
    { id, hidden, type: "creator" },
    {
      token: token,
      address: walletAddress,
    }
  );
  return res.hideNft;
};

export const getApplications = async (
  filters: GetApplicationsQueryVariables
) => {
  const res = await client.GetApplications(filters);
  return res.artistApplications;
};

export const approveApplication = async (
  id: number,
  walletAddress: string,
  token: string
) => {
  const res = await client.ApproveApplication(
    { id },
    {
      token: token,
      address: walletAddress,
    }
  );
  return res.approveArtistApplication;
};

export const rejectApplication = async (
  id: number,
  sendEmail: boolean,
  walletAddress: string,
  token: string
) => {
  const res = await client.RejectApplication(
    { id, sendEmail },
    {
      token: token,
      address: walletAddress,
    }
  );
  return res.rejectArtistApplication;
};

// export const getSelections = async () => {
//   const res = await client.GetCuratedSelections();
//   return res.curatedSelections;
// };

// export const getSelection = async (id: number) => {
//   const res = await client.GetCuratedSelection({ id });
//   return res.curatedSelection;
// };

export const getRandomNfts = async (quantity: number) => {
  const res = await client.GetRandomNfts({ quantity });
  return res.nfts;
};

// export const createSelection = async (walletAddress: string, token: string) => {
//   const res = await client.CreateCuratedSelection(undefined, {
//     token: token,
//     address: walletAddress,
//   });
//   return res.createCuratedSelection;
// };

// export const updateSelection = async (
//   args: UpdateCuratedSelectionMutationVariables,
//   walletAddress: string,
//   token: string
// ) => {
//   const res = await client.UpdateCuratedSelection(args, {
//     token: token,
//     address: walletAddress,
//   });
//   return res.updateCuratedSelection;
// };

// export const updateSelectionArtists = async (
//   id: number,
//   addedArtists: number[],
//   removedArtists: number[],
//   walletAddress: string,
//   token: string
// ) => {
//   const res = await client.UpdateCuratedSelectionArtists(
//     { id, addedArtists, removedArtists },
//     {
//       token: token,
//       address: walletAddress,
//     }
//   );
//   return (
//     res.addArtistsToCuratedSelection && res.removeArtistsFromCuratedSelection
//   );
// };

// export const updateSelectionNfts = async (
//   id: number,
//   addedNfts: number[],
//   removedNfts: number[],
//   walletAddress: string,
//   token: string
// ) => {
//   const res = await client.UpdateCuratedSelectionNfts(
//     { id, addedNfts, removedNfts },
//     {
//       token: token,
//       address: walletAddress,
//     }
//   );
//   return res.addNftsToCuratedSelection && res.removeNftsFromCuratedSelection;
// };

export const getTrades = async (walletAddress: string, token: string) => {
  const res = await client.GetTrades(undefined, {
    token: token,
    address: walletAddress,
  });
  return res.trades;
};

export const getChartsData = async () => {
  const res = await client.GetChartsData();
  return res;
};

// export const getInterviews = async (walletAddress: string, token: string) => {
//   const res = await client.GetInterviews(undefined, {
//     token: token,
//     address: walletAddress,
//   });
//   return res.interviews;
// };

// export const getInterview = async (
//   id: number,
//   walletAddress: string,
//   token: string
// ) => {
//   const res = await client.GetInterview(
//     { id },
//     {
//       token: token,
//       address: walletAddress,
//     }
//   );
//   return res.interview;
// };

// export const createInterview = async (
//   args: CreateInterviewMutationVariables,
//   walletAddress: string,
//   token: string
// ) => {
//   const res = await client.CreateInterview(args, {
//     token: token,
//     address: walletAddress,
//   });
//   return res.createInterview;
// };

// export const updateInterview = async (
//   args: UpdateInterviewMutationVariables,
//   walletAddress: string,
//   token: string
// ) => {
//   const res = await client.UpdateInterview(args, {
//     token: token,
//     address: walletAddress,
//   });
//   return res.updateInterview;
// };

// export const deleteInterview = async (
//   id: number,
//   walletAddress: string,
//   token: string
// ) => {
//   const res = await client.DeleteInterview(
//     { id },
//     {
//       token: token,
//       address: walletAddress,
//     }
//   );
//   return res.deleteInterview;
// };

export const updateUserGenre = async (
  id: number,
  genre: string | null,
  walletAddress: string,
  token: string
) => {
  const res = await client.updateUserGenre(
    { id, genre },
    {
      token: token,
      address: walletAddress,
    }
  );
  return res.updateUser;
};
