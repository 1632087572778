import CryptoJS from "crypto-js";
import moment from "moment";
import { getOpenseaImageUrl, getVideoThumbnail } from "./externalCalls";

import {
  authKey,
  iv,
  AWS_S3_THUMBNAILS_BASE_URL,
  IMGIX_BASE_URL,
  IPFS_BASE_URL,
  assetBaseUrl,
  thumbnailsBaseUrl,
} from "./constants";

export const getEncryptedAuthToken = (sig: string) => {
  const timestamp = moment().unix() * 1000;
  var encrypted = CryptoJS.AES.encrypt(
    `${sig}-${timestamp}`,
    CryptoJS.enc.Hex.parse(authKey),
    {
      mode: CryptoJS.mode.CBC,
      iv: CryptoJS.enc.Hex.parse(iv),
    }
  );

  return encrypted.toString();
};

export const areEqual = (str1: string, str2: string) => {
  try {
    return str1.toLowerCase() === str2.toLowerCase();
  } catch {
    return false;
  }
};

export const trimAddress = (address: string) => {
  // if it's not an ethereum address, return it as it is
  // if (!ethAddressRegex.test(address)) return address;
  // otherwise, return the first 4 and last 4 characters
  const length = address.length;
  return `${address.slice(0, 4)}...${address.slice(length - 4, length)}`;
};

export function createArtLink(delimiter = "-", ...args: string[]) {
  if (!args.length) return "";
  return args
    .join(" ")
    .split(" ")
    .filter((val) => val.trim())
    .join(delimiter)
    .replaceAll(/[#?/%]+/g, "");
}

export const createNavigateableAWSTumbnail = (url = "") => {
  const isIPFSImg = /ipfs:\/\//.test(url);
  return isIPFSImg
    ? `${AWS_S3_THUMBNAILS_BASE_URL}/${url.split("//")[1].split("/")[0]}.jpg`
    : "";
};

export const createNavigateableImgixUrl = (url = "") => {
  const isIPFSImg = /ipfs:\/\//.test(url);
  return isIPFSImg ? `${IMGIX_BASE_URL}/${url.split("//")[1]}` : "";
};

export const createNavigateableUrl = (url = "") => {
  const isIPFSImg = /ipfs:\/\//.test(url);
  return isIPFSImg ? `${IPFS_BASE_URL}/${url.split("//")[1]}` : url;
};

export const getImageUrl = (url: string) => {
  if (url.includes("/")) return createNavigateableUrl(url); // this means that the artwork is not processed yet
  return `${assetBaseUrl}/${url}`;
};

export const getThumbnailUrl = (url: string) => {
  if (url.includes("/")) return createNavigateableUrl(url);
  const name = url.split(".")[0];
  return `${thumbnailsBaseUrl}/${name}.jpg`;
};

export const getNftUrlForNewsletter = async (
  url: string,
  tokenId: number | null | undefined,
  contractAddress: string,
  assetType: string,
  platform: string,
  width: number,
  height: number,
  isCompressed: boolean
) => {
  if (platform !== "ninfa") {
    if (tokenId === null || tokenId === undefined) return url;
    if (assetType === "video") {
      return await getOpenseaImageUrl(tokenId, contractAddress);
    }
    return url;
  }
  if (assetType === "video") {
    // if (tokenId !== null && tokenId !== undefined) {
    //   return await getOpenseaImageUrl(tokenId, contractAddress);
    // }
    const hash = url.split("/")[2];
    return await getVideoThumbnail(hash);
  }
  return `${createNavigateableImgixUrl(url)}?q=${isCompressed ? 30 : 100}&w=${
    isCompressed ? Math.floor(width / 4) : width
  }&h=${isCompressed ? Math.floor(height / 4) : height}`;
};

export const formatEthPrice = (ethPrice: number) => {
  try {
    var stringPrice = String(ethPrice);
    var trailingZeros = 0;
    for (let digit of stringPrice.split("").reverse()) {
      if (digit === "0") {
        trailingZeros++;
      } else {
        break;
      }
    }
    if (trailingZeros > 0 && stringPrice.includes(".")) {
      stringPrice = stringPrice.slice(0, stringPrice.length - trailingZeros);
      if (stringPrice[stringPrice.length - 1] === ".") {
        stringPrice = stringPrice.slice(0, stringPrice.length - 1);
      }
    }
    return stringPrice;
  } catch {
    return ethPrice.toString();
  }
};

export const formatUsdPrice = (usdPrice: number) => {
  if (isNaN(usdPrice)) return "$0";
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });
  return formatter.format(usdPrice);
};

/**
 * Generates a random number with the specified number of digits.
 * @param digits - The number of digits the generated number should have.
 * @returns The generated random number.
 */
export const generateRandomNumber = (digits: number) => {
  const lowerBound = Math.pow(10, digits - 1);
  const upperBound = Math.pow(10, digits);
  return Math.floor(lowerBound + Math.random() * (upperBound - lowerBound));
};

export const toUtf8 = (str: string) => {
  return Buffer.from(str, "latin1").toString("utf-8");
};

export const replaceHtmlSpecialChar = (str: string) => {
  const htmlEntities = {
    "&": "%26",
    '"': "%22",
    "'": "%27",
  };
  let newStr = str;
  newStr = newStr.replaceAll(
    /(\&|\"|\')/g,
    (match) => htmlEntities[match as keyof typeof htmlEntities]
  );
  return String(newStr);
};
