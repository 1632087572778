import { areEqual } from "./pureFunctions";

export const authKey = process.env.REACT_APP_AUTH_KEY as string;

export const iv = process.env.REACT_APP_AUTH_IV as string;

export const getAdminName = (walletAddress: string) => {
  if (areEqual(walletAddress, "0x4C2A7a992A1474DF18012AD1D79b3376e9051e8f")) {
    return "Lorenzo";
  }
  if (areEqual(walletAddress, "0xbD4567400EF90637F41E45f0E2E989E11c4E8Bf5")) {
    return "Carlo";
  }
  if (areEqual(walletAddress, "0x8c729d8c76BeeCd472E942407Ad30Cc95c652A29")) {
    return "Brando";
  }
  return "unknown";
};

export const AWS_S3_THUMBNAILS_BASE_URL =
  "https://ninfa-assets.s3.eu-south-1.amazonaws.com/thumbnails";

export const IMGIX_BASE_URL = "https://ninfa.imgix.net";

export const profileImageBaseUrl =
  "https://storage.googleapis.com/ninfa-assets/profileImages/";

export const curatedSelectionBannerBaseUrl =
  "https://storage.googleapis.com/ninfa-assets/curatedSelectionBanners/";

export const IPFS_BASE_URL = "https://ninfa.infura-ipfs.io/ipfs";

export const compressedImageBaseUrl = "https://images.ninfa.io/nfts/original";

export const assetBaseUrl =
  "https://storage.googleapis.com/ninfa-assets/nfts/original";

export const compressedVideoBaseUrl =
  "https://storage.googleapis.com/ninfa-assets/nfts/animated";

export const thumbnailsBaseUrl =
  "https://storage.googleapis.com/ninfa-assets/nfts/thumbnail";

export const artistGenres = [
  "Conceptual Photography",
  "Landscape Photography",
  "Nude Photography",
  "Urban & Street Photography",
  "Aerial Photography",
  "Animation",
  "Illustration",
  "2D Art",
  "3D Animation",
  "3D Art",
  "AI",
  "Glitch Art",
  "Digital Paintings",
  "Paintings",
  "Visual Art",
];
