import React from "react";
import { Button } from "@mui/material";

import { useWeb3Modal } from "@web3modal/wagmi/react";

const LoginPage = () => {
  const { open } = useWeb3Modal();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Button onClick={() => open()}>Login</Button>
    </div>
  );
};

export default LoginPage;
