import { GraphQLClient } from 'graphql-request';
import { GraphQLClientRequestHeaders } from 'graphql-request/build/cjs/types';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

/** This represents a Activity */
export type Activity = {
  __typename?: 'Activity';
  amount: Scalars['Int']['output'];
  contractAddress: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  date: Scalars['String']['output'];
  event: Scalars['String']['output'];
  from?: Maybe<User>;
  fromAddress: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  network: Scalars['Int']['output'];
  nft?: Maybe<Nft>;
  nftId?: Maybe<Scalars['Int']['output']>;
  platform: NftPlatform;
  price: Scalars['Float']['output'];
  to?: Maybe<User>;
  toAddress: Scalars['String']['output'];
  tokenId?: Maybe<Scalars['Int']['output']>;
  transactionHash?: Maybe<Scalars['String']['output']>;
};

/** This represents a Article */
export type Article = {
  __typename?: 'Article';
  artist?: Maybe<User>;
  artistId?: Maybe<Scalars['Int']['output']>;
  author: Scalars['String']['output'];
  body: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  excerpt: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  image1?: Maybe<Scalars['String']['output']>;
  image2?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

/** This represents a ArtistApplication */
export type ArtistApplication = {
  __typename?: 'ArtistApplication';
  acceptedTerms: Scalars['String']['output'];
  artworkLink?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  email: Scalars['String']['output'];
  hasArtwork: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  numberOfApplications: Scalars['Int']['output'];
  profileImage?: Maybe<Scalars['String']['output']>;
  question1: Scalars['String']['output'];
  question2: Scalars['String']['output'];
  question3: Scalars['String']['output'];
  status: Scalars['String']['output'];
  twitter: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
  username: Scalars['String']['output'];
  walletAddress: Scalars['String']['output'];
};

export enum ArtistApplicationStatus {
  ArtistApplicationStatusApproved = 'ARTIST_APPLICATION_STATUS_APPROVED',
  ArtistApplicationStatusCanapplyagain = 'ARTIST_APPLICATION_STATUS_CANAPPLYAGAIN',
  ArtistApplicationStatusPending = 'ARTIST_APPLICATION_STATUS_PENDING',
  ArtistApplicationStatusRejected = 'ARTIST_APPLICATION_STATUS_REJECTED'
}

/** This represents a Auction */
export type Auction = {
  __typename?: 'Auction';
  auctionAddress: Scalars['String']['output'];
  auctionId: Scalars['Int']['output'];
  bids: Array<Bid>;
  commissionBps: Scalars['String']['output'];
  commissionBpsArray: Array<Scalars['Int']['output']>;
  commissionReceivers: Scalars['String']['output'];
  commissionReceiversArray: Array<Scalars['String']['output']>;
  commissionReceiversObjects: Array<Maybe<User>>;
  contractAddress: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  minimumIncrement: Scalars['Int']['output'];
  nft?: Maybe<Nft>;
  nftId?: Maybe<Scalars['Int']['output']>;
  reservePrice: Scalars['Float']['output'];
  seller?: Maybe<User>;
  sellerAddress: Scalars['String']['output'];
  sellerId?: Maybe<Scalars['Int']['output']>;
  startTime?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
  tokenId: Scalars['Int']['output'];
  transactionHash: Scalars['String']['output'];
};

export enum AuctionStatus {
  AuctionStatusCancelled = 'AUCTION_STATUS_CANCELLED',
  AuctionStatusEnded = 'AUCTION_STATUS_ENDED',
  AuctionStatusPending = 'AUCTION_STATUS_PENDING',
  AuctionStatusStarted = 'AUCTION_STATUS_STARTED'
}

/** This represents a Bid */
export type Bid = {
  __typename?: 'Bid';
  auction: Auction;
  auctionId: Scalars['Int']['output'];
  bidPrice: Scalars['Float']['output'];
  bidder?: Maybe<User>;
  bidderAddress: Scalars['String']['output'];
  bidderId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['String']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  transactionHash: Scalars['String']['output'];
};

export type Collection = {
  __typename?: 'Collection';
  banner?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  nftCount: Scalars['Int']['output'];
  nfts: Array<Nft>;
  owner: User;
  ownerId: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  type: CollectionType;
};


export type CollectionNftsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Array<InputMaybe<CollectionType>>>;
};

export enum CollectionType {
  CollectionTypeCollection = 'COLLECTION_TYPE_collection',
  CollectionTypeCuration = 'COLLECTION_TYPE_curation'
}

/** This represents a Contract */
export type Contract = {
  __typename?: 'Contract';
  contractAddress: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  creator?: Maybe<User>;
  creatorAddress: Scalars['String']['output'];
  creatorId?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDeployed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  network: Scalars['Int']['output'];
  nfts: Array<Nft>;
  platform: Scalars['String']['output'];
  royaltyBps?: Maybe<Scalars['Int']['output']>;
  royaltyReceiver?: Maybe<Scalars['String']['output']>;
  symbol: Scalars['String']['output'];
  transactionHash: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export enum ContractTypes {
  ContractTypeErc721 = 'CONTRACT_TYPE_ERC721',
  ContractTypeErc721Lazymint = 'CONTRACT_TYPE_ERC721LAZYMINT',
  ContractTypeErc721Lazymintyom = 'CONTRACT_TYPE_ERC721LAZYMINTYOM',
  ContractTypeErc1155 = 'CONTRACT_TYPE_ERC1155'
}

/** This represents a Curation */
export type Curation = {
  __typename?: 'Curation';
  artists: Array<User>;
  banner?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  date: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  genre?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  link: Scalars['String']['output'];
  nfts: Array<Nft>;
  numberOfArtists: Scalars['Int']['output'];
  organizer: Scalars['String']['output'];
  organizerImage?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};


/** This represents a Curation */
export type CurationNftsArgs = {
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

/** This represents a FavoriteArtist */
export type FavoriteArtist = {
  __typename?: 'FavoriteArtist';
  artist?: Maybe<User>;
  artistId: Scalars['Int']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

/** This represents a Feed */
export type Feed = {
  __typename?: 'Feed';
  amount: Scalars['Int']['output'];
  assetType: Scalars['String']['output'];
  contractAddress: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  date: Scalars['String']['output'];
  event: Scalars['String']['output'];
  fromAddress: Scalars['String']['output'];
  fromId?: Maybe<Scalars['Int']['output']>;
  fromUsername?: Maybe<Scalars['String']['output']>;
  height: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  imageUrl: Scalars['String']['output'];
  isCompressed: Scalars['Boolean']['output'];
  network: Scalars['Int']['output'];
  nftId: Scalars['Int']['output'];
  nftPlatform: Scalars['String']['output'];
  originalImageUrl?: Maybe<Scalars['String']['output']>;
  platform: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  title: Scalars['String']['output'];
  toAddress: Scalars['String']['output'];
  toId?: Maybe<Scalars['Int']['output']>;
  toUsername?: Maybe<Scalars['String']['output']>;
  tokenId?: Maybe<Scalars['Int']['output']>;
  transactionHash?: Maybe<Scalars['String']['output']>;
  width: Scalars['Int']['output'];
};

/** Returns homepage data */
export type Home = {
  __typename?: 'Home';
  artist?: Maybe<User>;
  artistId?: Maybe<Scalars['Int']['output']>;
  artistNftIds: Scalars['String']['output'];
  artistNfts: Array<Nft>;
  curatedPicksNftIds: Scalars['String']['output'];
  curatedPicksNfts: Array<Nft>;
  curation?: Maybe<Curation>;
  curationId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
};

/** This represents a LabsPage */
export type LabsPage = {
  __typename?: 'LabsPage';
  artist?: Maybe<Scalars['String']['output']>;
  artistLink?: Maybe<Scalars['String']['output']>;
  assetType: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  orientation: Scalars['String']['output'];
  pageLink?: Maybe<Scalars['String']['output']>;
  screen: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

/** Root Mutation */
export type Mutation = {
  __typename?: 'Mutation';
  /** Add artists to a curated selection */
  addArtistsToCuration: Scalars['Boolean']['output'];
  /** Add nfts to a curated selection */
  addNftsToCuration: Scalars['Boolean']['output'];
  /** Allows an admin to approve an artist application */
  approveArtistApplication: Scalars['Boolean']['output'];
  /** Allows a user to toggle multiple favorite artists at once. Takes an array of artistIds and toggles them for the user. */
  bulkUpdateFavoriteArtists: Scalars['Boolean']['output'];
  /** Allows a user to cancel a lazy order for ERC1155 tokens. The creator must be authenticated */
  cancel1155LazyOrder?: Maybe<Scalars['Boolean']['output']>;
  /** Allows a user to create a lazy order for ERC721 tokens. The creator must be authenticated */
  create721LazyOrder?: Maybe<Order>;
  /** Allows a user to create a lazy order for ERC1155 tokens. The creator must be authenticated */
  create1155LazyOrder?: Maybe<Order>;
  /** Allows a user to create an artist application. The creator must be authenticated, but not authorized becaues it sould not exist as user yet */
  createArtistApplication?: Maybe<ArtistApplication>;
  /** Allows a user to create a collection. The creator must be authenticated */
  createCollection?: Maybe<Collection>;
  /** Allows a user to create a contract. The creator must be authenticated */
  createContract?: Maybe<Contract>;
  /** Allows a user to create an NFT. The creator must be authenticated */
  createNft?: Maybe<Nft>;
  /** Allows a user to delete a collection. The user must be authenticated */
  deleteCollection: Scalars['Boolean']['output'];
  /** Allows a user to delete a lazy minted NFT. The creator must be authenticated */
  deleteLazyNft: Scalars['Boolean']['output'];
  /** Allows a user to edit the initial order for ERC1155 tokens. The creator must be authenticated */
  edit1155InitialOrder?: Maybe<Scalars['Boolean']['output']>;
  /** Allows a user hide and unHide an NFT. The creator must be authenticated */
  hideNft: Scalars['Boolean']['output'];
  /** Mark a Superrare order as internal to know if it was initiated by NINFA interface */
  markSuperrareOrderAsInternal?: Maybe<Scalars['Boolean']['output']>;
  /** Allows a new user to register for a custom project reminder */
  registerForCustomProjectReminder: Scalars['Boolean']['output'];
  /** Allows a new user to register additional wallets. Needs authentication and authorization. */
  registerUserWallets?: Maybe<Scalars['Boolean']['output']>;
  /** Allows an admin to reject an artist application */
  rejectArtistApplication: Scalars['Boolean']['output'];
  /** Reject an offer */
  rejectOffer?: Maybe<Offer>;
  /** Remove artists from a curated selection */
  removeArtistsFromCuration: Scalars['Boolean']['output'];
  /** Remove nfts from a curated selection */
  removeNftsFromCuration: Scalars['Boolean']['output'];
  /** Allows a new user to sign up */
  signUp?: Maybe<User>;
  /** Allows a user to toggle a favorite artist */
  toggleFavoriteArtist: Scalars['Boolean']['output'];
  /** Allows an admin to update an artist application */
  updateArtistApplication?: Maybe<ArtistApplication>;
  /** Allows a user to update a collection. The user must be authenticated */
  updateCollection?: Maybe<Collection>;
  /** Allows a user to add or remove NFTs from a collection. The user must be authenticated */
  updateCollectionNfts: Scalars['Boolean']['output'];
  /** Allows a new user to update their newsletter subscription */
  updateNewsletterSubscription: Scalars['Boolean']['output'];
  /** Allows a new user to update their profile, needs auuthentication and autorization. */
  updateUser?: Maybe<User>;
  /** Allows a new user to update their details, needs authentication and autorization. If first time, will create a new user details row in the db */
  updateUserDetails?: Maybe<UserDetails>;
  /** Allows an admin to update a user's type. Needs authentication and authorization. */
  updateUserType: Scalars['Boolean']['output'];
};


/** Root Mutation */
export type MutationAddArtistsToCurationArgs = {
  artistIds: Array<InputMaybe<Scalars['Int']['input']>>;
  selectionId: Scalars['Int']['input'];
};


/** Root Mutation */
export type MutationAddNftsToCurationArgs = {
  nftIds: Array<InputMaybe<Scalars['Int']['input']>>;
  selectionId: Scalars['Int']['input'];
};


/** Root Mutation */
export type MutationApproveArtistApplicationArgs = {
  id: Scalars['Int']['input'];
};


/** Root Mutation */
export type MutationBulkUpdateFavoriteArtistsArgs = {
  addedArtistIds: Array<Scalars['Int']['input']>;
  removedArtistIds: Array<Scalars['Int']['input']>;
  userId: Scalars['Int']['input'];
};


/** Root Mutation */
export type MutationCancel1155LazyOrderArgs = {
  id: Scalars['Int']['input'];
};


/** Root Mutation */
export type MutationCreate721LazyOrderArgs = {
  buyerAddress?: InputMaybe<Scalars['String']['input']>;
  commissionBps?: InputMaybe<Scalars['String']['input']>;
  commissionReceivers?: InputMaybe<Scalars['String']['input']>;
  contractAddress: Scalars['String']['input'];
  endTime: Scalars['String']['input'];
  feeAccount?: InputMaybe<Scalars['String']['input']>;
  nftId: Scalars['Int']['input'];
  salt: Scalars['String']['input'];
  sellerAddress: Scalars['String']['input'];
  sellerId: Scalars['Int']['input'];
  signature: Scalars['String']['input'];
  tokenId?: InputMaybe<Scalars['Int']['input']>;
  type: OrderTypes;
  unitPrice: Scalars['Float']['input'];
};


/** Root Mutation */
export type MutationCreate1155LazyOrderArgs = {
  amount: Scalars['Int']['input'];
  commissionBps?: InputMaybe<Scalars['String']['input']>;
  commissionReceivers?: InputMaybe<Scalars['String']['input']>;
  contractAddress: Scalars['String']['input'];
  endTime: Scalars['String']['input'];
  maxAmount: Scalars['Int']['input'];
  nftId: Scalars['Int']['input'];
  sellerAddress: Scalars['String']['input'];
  sellerId: Scalars['Int']['input'];
  signature: Scalars['String']['input'];
  type: OrderTypes;
  unitPrice: Scalars['Float']['input'];
};


/** Root Mutation */
export type MutationCreateArtistApplicationArgs = {
  acceptedTerms: Scalars['String']['input'];
  artworkLink?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  hasArtwork: Scalars['Boolean']['input'];
  numberOfApplications: Scalars['Int']['input'];
  profileImage?: InputMaybe<Scalars['String']['input']>;
  question1: Scalars['String']['input'];
  question2: Scalars['String']['input'];
  question3: Scalars['String']['input'];
  status: ArtistApplicationStatus;
  twitter: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
  username: Scalars['String']['input'];
  walletAddress: Scalars['String']['input'];
};


/** Root Mutation */
export type MutationCreateCollectionArgs = {
  banner?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  ownerId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  type: CollectionType;
};


/** Root Mutation */
export type MutationCreateContractArgs = {
  contractAddress: Scalars['String']['input'];
  creatorAddress: Scalars['String']['input'];
  creatorId: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  network: NftNetwork;
  platform: NftPlatform;
  royaltyBps?: InputMaybe<Scalars['Int']['input']>;
  royaltyReceiver?: InputMaybe<Scalars['String']['input']>;
  symbol: Scalars['String']['input'];
  transactionHash: Scalars['String']['input'];
  type: ContractTypes;
};


/** Root Mutation */
export type MutationCreateNftArgs = {
  amount: Scalars['Int']['input'];
  assetType: NftAssetType;
  attributes?: InputMaybe<Scalars['String']['input']>;
  collaborators?: InputMaybe<Scalars['String']['input']>;
  contractAddress: Scalars['String']['input'];
  creatorAddress: Scalars['String']['input'];
  creatorId?: InputMaybe<Scalars['Int']['input']>;
  description: Scalars['String']['input'];
  height: Scalars['Int']['input'];
  hiddenByOGCreator: Scalars['Boolean']['input'];
  imageUrl: Scalars['String']['input'];
  isMinted?: InputMaybe<Scalars['Boolean']['input']>;
  listedOn?: InputMaybe<Array<InputMaybe<NftPlatform>>>;
  listingType?: InputMaybe<NftListingType>;
  metadataUrl: Scalars['String']['input'];
  network: NftNetwork;
  originalImageUrl?: InputMaybe<Scalars['String']['input']>;
  ownerAddress?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  platform: NftPlatform;
  price?: InputMaybe<Scalars['Int']['input']>;
  royaltyBps?: InputMaybe<Scalars['Int']['input']>;
  royaltyReceivers?: InputMaybe<Scalars['String']['input']>;
  royaltySplit?: InputMaybe<Scalars['String']['input']>;
  standard: NftStandard;
  title: Scalars['String']['input'];
  tokenId?: InputMaybe<Scalars['Int']['input']>;
  transactionHash?: InputMaybe<Scalars['String']['input']>;
  width: Scalars['Int']['input'];
};


/** Root Mutation */
export type MutationDeleteCollectionArgs = {
  collectionId: Scalars['Int']['input'];
};


/** Root Mutation */
export type MutationDeleteLazyNftArgs = {
  id: Scalars['Int']['input'];
};


/** Root Mutation */
export type MutationEdit1155InitialOrderArgs = {
  endTime?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  maxAmount?: InputMaybe<Scalars['Int']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};


/** Root Mutation */
export type MutationHideNftArgs = {
  hidden: Scalars['Boolean']['input'];
  id: Scalars['Int']['input'];
  type: Scalars['String']['input'];
};


/** Root Mutation */
export type MutationMarkSuperrareOrderAsInternalArgs = {
  internalOrderId: Scalars['Int']['input'];
};


/** Root Mutation */
export type MutationRegisterForCustomProjectReminderArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<Scalars['String']['input']>;
};


/** Root Mutation */
export type MutationRegisterUserWalletsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  walletAddresses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** Root Mutation */
export type MutationRejectArtistApplicationArgs = {
  id: Scalars['Int']['input'];
  sendEmail: Scalars['Boolean']['input'];
};


/** Root Mutation */
export type MutationRejectOfferArgs = {
  id: Scalars['Int']['input'];
};


/** Root Mutation */
export type MutationRemoveArtistsFromCurationArgs = {
  artistIds: Array<InputMaybe<Scalars['Int']['input']>>;
  selectionId: Scalars['Int']['input'];
};


/** Root Mutation */
export type MutationRemoveNftsFromCurationArgs = {
  nftIds: Array<InputMaybe<Scalars['Int']['input']>>;
  selectionId: Scalars['Int']['input'];
};


/** Root Mutation */
export type MutationSignUpArgs = {
  acceptedTerms: Scalars['String']['input'];
  approvedAt?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  walletAddress: Scalars['String']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
};


/** Root Mutation */
export type MutationToggleFavoriteArtistArgs = {
  artistId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


/** Root Mutation */
export type MutationUpdateArtistApplicationArgs = {
  acceptedTerms?: InputMaybe<Scalars['String']['input']>;
  artworkLink?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  hasArtwork?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  numberOfApplications?: InputMaybe<Scalars['Int']['input']>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  question1?: InputMaybe<Scalars['String']['input']>;
  question2?: InputMaybe<Scalars['String']['input']>;
  question3?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ArtistApplicationStatus>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  walletAddress?: InputMaybe<Scalars['String']['input']>;
};


/** Root Mutation */
export type MutationUpdateCollectionArgs = {
  banner?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};


/** Root Mutation */
export type MutationUpdateCollectionNftsArgs = {
  addedNftIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  collectionId: Scalars['Int']['input'];
  removedNftIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};


/** Root Mutation */
export type MutationUpdateNewsletterSubscriptionArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  subscribe?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Root Mutation */
export type MutationUpdateUserArgs = {
  approvedAt?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  detailsFilled?: InputMaybe<Scalars['Boolean']['input']>;
  discord?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  genre?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  instagram?: InputMaybe<Scalars['String']['input']>;
  isTermsAccepted?: InputMaybe<Scalars['String']['input']>;
  onCyberLink?: InputMaybe<Scalars['String']['input']>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};


/** Root Mutation */
export type MutationUpdateUserDetailsArgs = {
  city?: InputMaybe<Scalars['String']['input']>;
  dob?: InputMaybe<Scalars['String']['input']>;
  emailSettings?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  unicode?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['Int']['input'];
  vatNumber?: InputMaybe<Scalars['String']['input']>;
};


/** Root Mutation */
export type MutationUpdateUserTypeArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  userType?: InputMaybe<Scalars['String']['input']>;
};

/** This represents an Nft */
export type Nft = {
  __typename?: 'Nft';
  activities: Array<Activity>;
  amount: Scalars['Int']['output'];
  assetType: Scalars['String']['output'];
  attributes?: Maybe<Array<Maybe<NftAttributes>>>;
  auctions: Array<Auction>;
  collaborators?: Maybe<Scalars['String']['output']>;
  collections: Array<Collection>;
  contract?: Maybe<Contract>;
  contractAddress: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  creators: Array<NftCreator>;
  currency?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  height: Scalars['Int']['output'];
  hiddenByOGCreator: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  imageUrl: Scalars['String']['output'];
  isBurnt: Scalars['Boolean']['output'];
  isCompressed: Scalars['Boolean']['output'];
  isCuratedPick: Scalars['Boolean']['output'];
  isFeaturedArtist: Scalars['Boolean']['output'];
  isMinted: Scalars['Boolean']['output'];
  legacyId?: Maybe<Scalars['Int']['output']>;
  listedOn?: Maybe<Scalars['String']['output']>;
  listingType: Scalars['String']['output'];
  metadataUrl: Scalars['String']['output'];
  minimumOffer: Scalars['Float']['output'];
  network: Scalars['Int']['output'];
  offers: Array<Offer>;
  ogCreator?: Maybe<User>;
  orders: Array<Order>;
  originalImageUrl?: Maybe<Scalars['String']['output']>;
  owners: Array<NftOwner>;
  platform: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  royaltyBps?: Maybe<Scalars['Int']['output']>;
  royaltyReceivers?: Maybe<Scalars['String']['output']>;
  royaltySplit?: Maybe<Scalars['String']['output']>;
  seeAlso: Array<Nft>;
  standard: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  tokenId?: Maybe<Scalars['Int']['output']>;
  trades: Array<Trade>;
  transactionHash?: Maybe<Scalars['String']['output']>;
  width: Scalars['Int']['output'];
};


/** This represents an Nft */
export type NftOwnersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ownerAddress?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
};

export enum NftAssetType {
  AssetTypeGif = 'ASSET_TYPE_GIF',
  AssetTypeHtml = 'ASSET_TYPE_HTML',
  AssetTypeImage = 'ASSET_TYPE_IMAGE',
  AssetTypeVideo = 'ASSET_TYPE_VIDEO'
}

/** This represents the attributes of an NFT */
export type NftAttributes = {
  __typename?: 'NftAttributes';
  attribute: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** This represents an NftCreator */
export type NftCreator = {
  __typename?: 'NftCreator';
  creator?: Maybe<User>;
  creatorAddress: Scalars['String']['output'];
  creatorId?: Maybe<Scalars['Int']['output']>;
  hidden: Scalars['Boolean']['output'];
  isOG: Scalars['Boolean']['output'];
};

export enum NftListingType {
  ListingTypeAuction = 'LISTING_TYPE_AUCTION',
  ListingTypeAuctionended = 'LISTING_TYPE_AUCTIONENDED',
  ListingTypeAuctionstarted = 'LISTING_TYPE_AUCTIONSTARTED',
  ListingTypeFixedprice = 'LISTING_TYPE_FIXEDPRICE',
  ListingTypeNotforsale = 'LISTING_TYPE_NOTFORSALE'
}

export enum NftMarket {
  MarketPrimary = 'MARKET_PRIMARY',
  MarketSecondary = 'MARKET_SECONDARY'
}

export enum NftNetwork {
  Network_1 = 'NETWORK_1',
  Network_8 = 'NETWORK_8',
  Network_8453 = 'NETWORK_8453',
  Network_42161 = 'NETWORK_42161',
  Network_84532 = 'NETWORK_84532',
  Network_421614 = 'NETWORK_421614'
}

/** This represents an NftOwner */
export type NftOwner = {
  __typename?: 'NftOwner';
  amount: Scalars['Int']['output'];
  hidden: Scalars['Boolean']['output'];
  owner?: Maybe<User>;
  ownerAddress: Scalars['String']['output'];
  ownerId?: Maybe<Scalars['Int']['output']>;
};

/** This represents a page of Nfts */
export type NftPage = {
  __typename?: 'NftPage';
  currentPage: Scalars['Int']['output'];
  nfts: Array<Nft>;
  totalPages: Scalars['Int']['output'];
};

export enum NftPlatform {
  PlatformClickcreate = 'PLATFORM_CLICKCREATE',
  PlatformCustomgen = 'PLATFORM_CUSTOMGEN',
  PlatformFoundation = 'PLATFORM_FOUNDATION',
  PlatformManifold = 'PLATFORM_MANIFOLD',
  PlatformNinfa = 'PLATFORM_NINFA',
  PlatformSuperrare = 'PLATFORM_SUPERRARE',
  PlatformTransient = 'PLATFORM_TRANSIENT',
  PlatformUnknown = 'PLATFORM_UNKNOWN'
}

export enum NftStandard {
  Standard_721 = 'STANDARD_721',
  Standard_1155 = 'STANDARD_1155'
}

/** This represents a Offer */
export type Offer = {
  __typename?: 'Offer';
  amount: Scalars['Int']['output'];
  contractAddress: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  marketplaceAddress: Scalars['String']['output'];
  nft?: Maybe<Nft>;
  nftId?: Maybe<Scalars['Int']['output']>;
  offerId: Scalars['Int']['output'];
  offerPrice: Scalars['Float']['output'];
  offerer?: Maybe<User>;
  offererAddress: Scalars['String']['output'];
  offererId?: Maybe<Scalars['Int']['output']>;
  receiver?: Maybe<User>;
  receiverAddress?: Maybe<Scalars['String']['output']>;
  receiverId?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
  tokenId: Scalars['Int']['output'];
  transactionHash: Scalars['String']['output'];
};

export enum OfferStatus {
  OfferStatusAccepted = 'OFFER_STATUS_ACCEPTED',
  OfferStatusCancelled = 'OFFER_STATUS_CANCELLED',
  OfferStatusPending = 'OFFER_STATUS_PENDING',
  OfferStatusRejected = 'OFFER_STATUS_REJECTED'
}

/** This represents a Order */
export type Order = {
  __typename?: 'Order';
  amount: Scalars['Int']['output'];
  buyerAddress?: Maybe<Scalars['String']['output']>;
  commissionBps: Scalars['String']['output'];
  commissionBpsArray: Array<Scalars['Int']['output']>;
  commissionReceivers: Scalars['String']['output'];
  commissionReceiversArray: Array<Scalars['String']['output']>;
  commissionReceiversObjects: Array<Maybe<User>>;
  contractAddress: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  feeAccount?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isInternal: Scalars['String']['output'];
  marketplaceAddress?: Maybe<Scalars['String']['output']>;
  maxAmount?: Maybe<Scalars['Int']['output']>;
  nft?: Maybe<Nft>;
  nftId?: Maybe<Scalars['Int']['output']>;
  orderId?: Maybe<Scalars['Int']['output']>;
  referrerAddress?: Maybe<Scalars['String']['output']>;
  salt?: Maybe<Scalars['String']['output']>;
  seller?: Maybe<User>;
  sellerAddress: Scalars['String']['output'];
  sellerId?: Maybe<Scalars['Int']['output']>;
  signature?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  tokenId?: Maybe<Scalars['Int']['output']>;
  transactionHash?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  unitPrice: Scalars['Float']['output'];
};

export enum OrderStatus {
  OrderStatusActive = 'ORDER_STATUS_ACTIVE',
  OrderStatusCancelled = 'ORDER_STATUS_CANCELLED',
  OrderStatusCancelledonchain = 'ORDER_STATUS_CANCELLEDONCHAIN'
}

export enum OrderTypes {
  OrderTypeEditioninitial = 'ORDER_TYPE_EDITIONINITIAL',
  OrderTypeLazy = 'ORDER_TYPE_LAZY',
  OrderTypeLazyedition = 'ORDER_TYPE_LAZYEDITION',
  OrderTypeOnchain = 'ORDER_TYPE_ONCHAIN'
}

/** Root Query */
export type Query = {
  __typename?: 'Query';
  /** A single Article */
  article?: Maybe<Article>;
  /** A list of Articles */
  articles: Array<Article>;
  /** A list of Artist Applications with filters */
  artistApplications: Array<ArtistApplication>;
  /** An Auction */
  auction?: Maybe<Auction>;
  /** A list of Auctions with filters */
  auctions: Array<Auction>;
  /** A list of collected artists */
  collectedArtistIds: Array<Scalars['Int']['output']>;
  /** A Collection */
  collection?: Maybe<Collection>;
  /** A list of Collections */
  collections: Array<Collection>;
  /** A Contract */
  contract?: Maybe<Contract>;
  /** A list of Contracts */
  contracts: Array<Contract>;
  /** A Curations */
  curation?: Maybe<Curation>;
  /** A Curations */
  curations: Array<Curation>;
  /** A list of Favorite Artists */
  favoriteArtists: Array<FavoriteArtist>;
  /** A list of Feed */
  feed: Array<Feed>;
  /** Gets homepage data */
  home?: Maybe<Home>;
  /** A LabsPage */
  labsPage: Array<LabsPage>;
  /** An Nft */
  nft?: Maybe<Nft>;
  /** A list of Nfts with filters */
  nfts: Array<Nft>;
  /** A list of Nfts with filters with pagination */
  nftsPage: NftPage;
  /** An Offer */
  offer?: Maybe<Offer>;
  /** A list of Offers with filters */
  offers: Array<Offer>;
  /** An Order */
  order?: Maybe<Order>;
  /** A list of Orders with filters */
  orders: Array<Order>;
  /** A list of recomended artists */
  recomendedArtists: Array<User>;
  /** A list of Nfts searched by title, creator username or creator wallet address */
  searchNfts: Array<Nft>;
  /** A Trade */
  trade?: Maybe<Trade>;
  /** A list of Trades with filters */
  trades: Array<Trade>;
  /** A Single User */
  user?: Maybe<User>;
  /** A Single User */
  userDetails?: Maybe<UserDetails>;
  /** A list of Users */
  users: Array<User>;
};


/** Root Query */
export type QueryArticleArgs = {
  id: Scalars['Int']['input'];
};


/** Root Query */
export type QueryArticlesArgs = {
  quantity?: InputMaybe<Scalars['Int']['input']>;
};


/** Root Query */
export type QueryArtistApplicationsArgs = {
  hasArtwork?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<ArtistApplicationStatus>>>;
};


/** Root Query */
export type QueryAuctionArgs = {
  auctionAddress: Scalars['String']['input'];
  auctionId: Scalars['Int']['input'];
};


/** Root Query */
export type QueryAuctionsArgs = {
  auctionAddress?: InputMaybe<Scalars['String']['input']>;
  contractAddress?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nftId?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  sellerAddress?: InputMaybe<Scalars['String']['input']>;
  sellerId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<InputMaybe<AuctionStatus>>>;
  tokenId?: InputMaybe<Scalars['Int']['input']>;
};


/** Root Query */
export type QueryCollectedArtistIdsArgs = {
  userId: Scalars['Int']['input'];
};


/** Root Query */
export type QueryCollectionArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Root Query */
export type QueryCollectionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Array<InputMaybe<CollectionType>>>;
};


/** Root Query */
export type QueryContractArgs = {
  contractAddress?: InputMaybe<Scalars['String']['input']>;
};


/** Root Query */
export type QueryContractsArgs = {
  creatorAddress?: InputMaybe<Scalars['String']['input']>;
  creatorId?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  network?: InputMaybe<Array<InputMaybe<NftNetwork>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Array<InputMaybe<NftPlatform>>>;
  type?: InputMaybe<Array<InputMaybe<ContractTypes>>>;
};


/** Root Query */
export type QueryCurationArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Root Query */
export type QueryFavoriteArtistsArgs = {
  userId: Scalars['Int']['input'];
};


/** Root Query */
export type QueryFeedArgs = {
  event?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  platforms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};


/** Root Query */
export type QueryLabsPageArgs = {
  screen?: InputMaybe<Scalars['Int']['input']>;
};


/** Root Query */
export type QueryNftArgs = {
  contractAddress?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isExternal?: InputMaybe<Scalars['Boolean']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  standard?: InputMaybe<NftStandard>;
  tokenId?: InputMaybe<Scalars['Int']['input']>;
};


/** Root Query */
export type QueryNftsArgs = {
  assetType?: InputMaybe<Array<InputMaybe<NftAssetType>>>;
  contractAddress?: InputMaybe<Scalars['String']['input']>;
  creatorAddress?: InputMaybe<Scalars['String']['input']>;
  creatorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  currency?: InputMaybe<Scalars['String']['input']>;
  genre?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  listedOn?: InputMaybe<Array<InputMaybe<NftPlatform>>>;
  listingType?: InputMaybe<Array<InputMaybe<NftListingType>>>;
  market?: InputMaybe<Array<InputMaybe<NftMarket>>>;
  maxPrice?: InputMaybe<Scalars['Float']['input']>;
  minPrice?: InputMaybe<Scalars['Float']['input']>;
  network?: InputMaybe<Array<InputMaybe<NftNetwork>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  ownerAddress?: InputMaybe<Scalars['String']['input']>;
  ownerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  platform?: InputMaybe<Array<InputMaybe<NftPlatform>>>;
  shape?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  showInCollection?: InputMaybe<Scalars['Boolean']['input']>;
  standard?: InputMaybe<Array<InputMaybe<NftStandard>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};


/** Root Query */
export type QueryNftsPageArgs = {
  assetType?: InputMaybe<Array<InputMaybe<NftAssetType>>>;
  contractAddress?: InputMaybe<Scalars['String']['input']>;
  creatorAddress?: InputMaybe<Scalars['String']['input']>;
  creatorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  currency?: InputMaybe<Scalars['String']['input']>;
  genre?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  listedOn?: InputMaybe<Array<InputMaybe<NftPlatform>>>;
  listingType?: InputMaybe<Array<InputMaybe<NftListingType>>>;
  market?: InputMaybe<Array<InputMaybe<NftMarket>>>;
  maxPrice?: InputMaybe<Scalars['Float']['input']>;
  minPrice?: InputMaybe<Scalars['Float']['input']>;
  network?: InputMaybe<Array<InputMaybe<NftNetwork>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  ownerAddress?: InputMaybe<Scalars['String']['input']>;
  ownerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  platform?: InputMaybe<Array<InputMaybe<NftPlatform>>>;
  shape?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  showInCollection?: InputMaybe<Scalars['Boolean']['input']>;
  standard?: InputMaybe<Array<InputMaybe<NftStandard>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};


/** Root Query */
export type QueryOfferArgs = {
  marketplaceAddress: Scalars['String']['input'];
  offerId: Scalars['Int']['input'];
};


/** Root Query */
export type QueryOffersArgs = {
  contractAddress?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  marketplaceAddress?: InputMaybe<Scalars['String']['input']>;
  maxAmount?: InputMaybe<Scalars['Int']['input']>;
  maxPrice?: InputMaybe<Scalars['Float']['input']>;
  minAmount?: InputMaybe<Scalars['Int']['input']>;
  minPrice?: InputMaybe<Scalars['Float']['input']>;
  nftId?: InputMaybe<Scalars['Int']['input']>;
  offererAddress?: InputMaybe<Scalars['String']['input']>;
  offererId?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  receiverAddress?: InputMaybe<Scalars['String']['input']>;
  receiverId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<InputMaybe<OfferStatus>>>;
  tokenId?: InputMaybe<Scalars['Int']['input']>;
};


/** Root Query */
export type QueryOrderArgs = {
  marketplaceAddress: Scalars['String']['input'];
  orderId: Scalars['Int']['input'];
};


/** Root Query */
export type QueryOrdersArgs = {
  contractAddress?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  marketplaceAddress?: InputMaybe<Scalars['String']['input']>;
  maxAmount?: InputMaybe<Scalars['Int']['input']>;
  maxUnitPrice?: InputMaybe<Scalars['Float']['input']>;
  minAmount?: InputMaybe<Scalars['Int']['input']>;
  minUnitPrice?: InputMaybe<Scalars['Float']['input']>;
  nftId?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  sellerAddress?: InputMaybe<Scalars['String']['input']>;
  sellerId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<InputMaybe<OrderStatus>>>;
  tokenId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Array<InputMaybe<OrderTypes>>>;
};


/** Root Query */
export type QueryRecomendedArtistsArgs = {
  quantity?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['Int']['input'];
};


/** Root Query */
export type QuerySearchNftsArgs = {
  search: Scalars['String']['input'];
};


/** Root Query */
export type QueryTradeArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Root Query */
export type QueryTradesArgs = {
  buyerAddress?: InputMaybe<Scalars['String']['input']>;
  buyerId?: InputMaybe<Scalars['Int']['input']>;
  contractAddress?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  isVolume?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxPrice?: InputMaybe<Scalars['Float']['input']>;
  minPrice?: InputMaybe<Scalars['Float']['input']>;
  network?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nftId?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sellerAddress?: InputMaybe<Scalars['String']['input']>;
  sellerId?: InputMaybe<Scalars['Int']['input']>;
  tokenId?: InputMaybe<Scalars['Int']['input']>;
  tradeType?: InputMaybe<Array<InputMaybe<TradeTypes>>>;
};


/** Root Query */
export type QueryUserArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  walletAddress?: InputMaybe<Scalars['String']['input']>;
};


/** Root Query */
export type QueryUserDetailsArgs = {
  userId?: InputMaybe<Scalars['Int']['input']>;
};


/** Root Query */
export type QueryUsersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  userType?: InputMaybe<Array<InputMaybe<UserType>>>;
};

/** This represents a Trade */
export type Trade = {
  __typename?: 'Trade';
  amount: Scalars['Int']['output'];
  buyer?: Maybe<User>;
  buyerAddress: Scalars['String']['output'];
  buyerId?: Maybe<Scalars['Int']['output']>;
  commissions: Scalars['Float']['output'];
  contractAddress: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  ethToUsd: Scalars['Float']['output'];
  eurToUsd: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  isSecondary: Scalars['String']['output'];
  isVolume: Scalars['String']['output'];
  network: Scalars['String']['output'];
  nft?: Maybe<Nft>;
  nftId?: Maybe<Scalars['Int']['output']>;
  ninfaFee: Scalars['Float']['output'];
  platform: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  royaltyFee: Scalars['Float']['output'];
  seller?: Maybe<User>;
  sellerAddress: Scalars['String']['output'];
  sellerId?: Maybe<Scalars['Int']['output']>;
  sellerRevenue: Scalars['Float']['output'];
  tokenId: Scalars['Int']['output'];
  tradeType: Scalars['String']['output'];
  transactionHash: Scalars['String']['output'];
};

export enum TradeTypes {
  TradeTypesAuction = 'TRADE_TYPES_AUCTION',
  TradeTypesMint = 'TRADE_TYPES_MINT',
  TradeTypesOffer = 'TRADE_TYPES_OFFER',
  TradeTypesOrder = 'TRADE_TYPES_ORDER'
}

/** This represents a generic user without considering the userType */
export type User = {
  __typename?: 'User';
  activities: Array<Activity>;
  appliedAt?: Maybe<Scalars['String']['output']>;
  approvedAt?: Maybe<Scalars['String']['output']>;
  auctions: Array<Auction>;
  bio?: Maybe<Scalars['String']['output']>;
  collectedNfts: Array<Nft>;
  collections: Array<Collection>;
  contracts: Array<Contract>;
  createYomStep: Scalars['Int']['output'];
  createdAt: Scalars['String']['output'];
  createdNfts: Array<Nft>;
  details?: Maybe<UserDetails>;
  detailsFilled: Scalars['Boolean']['output'];
  discord?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  favoriteArtists: Array<Scalars['Int']['output']>;
  genre?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  instagram?: Maybe<Scalars['String']['output']>;
  isDomus: Scalars['Boolean']['output'];
  isTermsAccepted: Scalars['Boolean']['output'];
  kycStatus?: Maybe<Scalars['String']['output']>;
  kycToken?: Maybe<Scalars['String']['output']>;
  lastCreated?: Maybe<Scalars['String']['output']>;
  latestAction: Scalars['String']['output'];
  legacyId?: Maybe<Scalars['Int']['output']>;
  offersMade: Array<Offer>;
  offersReceived: Array<Offer>;
  onCyberLink?: Maybe<Scalars['String']['output']>;
  orders: Array<Order>;
  profileImage?: Maybe<Scalars['String']['output']>;
  purchases: Array<Trade>;
  sales: Array<Trade>;
  status: Scalars['Boolean']['output'];
  twitter?: Maybe<Scalars['String']['output']>;
  userType: Scalars['String']['output'];
  username: Scalars['String']['output'];
  walletAddress: Scalars['String']['output'];
  walletAddresses: Array<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  yomType: Scalars['String']['output'];
};


/** This represents a generic user without considering the userType */
export type UserCollectedNftsArgs = {
  showCreated?: InputMaybe<Scalars['Boolean']['input']>;
};

/** This represents a generic user without considering the userType */
export type UserDetails = {
  __typename?: 'UserDetails';
  acceptedPrivacyPolicy?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  dob?: Maybe<Scalars['String']['output']>;
  emailSettings?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  nationality?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unicode?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  user: User;
  userId: Scalars['Int']['output'];
  vatNumber?: Maybe<Scalars['String']['output']>;
};

export enum UserType {
  UserTypeAdmin = 'USER_TYPE_ADMIN',
  UserTypeArtist = 'USER_TYPE_ARTIST',
  UserTypeCollector = 'USER_TYPE_COLLECTOR'
}

export type UpdateUserTypeMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  userType: Scalars['String']['input'];
}>;


export type UpdateUserTypeMutation = { __typename?: 'Mutation', updateUserType: boolean };

export type ApproveApplicationMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type ApproveApplicationMutation = { __typename?: 'Mutation', approveArtistApplication: boolean };

export type RejectApplicationMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  sendEmail: Scalars['Boolean']['input'];
}>;


export type RejectApplicationMutation = { __typename?: 'Mutation', rejectArtistApplication: boolean };

export type HideNftMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  hidden: Scalars['Boolean']['input'];
  type: Scalars['String']['input'];
}>;


export type HideNftMutation = { __typename?: 'Mutation', hideNft: boolean };

export type UpdateUserGenreMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  genre?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateUserGenreMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'User', id: number } | null };

export type SignInQueryVariables = Exact<{
  walletAddress: Scalars['String']['input'];
}>;


export type SignInQuery = { __typename?: 'Query', user?: { __typename?: 'User', userType: string } | null };

export type SearchUsersQueryVariables = Exact<{
  search: Scalars['String']['input'];
}>;


export type SearchUsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: number, walletAddress: string, username: string, email?: string | null, userType: string, twitter?: string | null, genre?: string | null }> };

export type GetArtistsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetArtistsQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: number, walletAddress: string, username: string, email?: string | null, userType: string, twitter?: string | null, genre?: string | null }> };

export type GetApplicationsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<ArtistApplicationStatus>>>;
  hasArtwork?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetApplicationsQuery = { __typename?: 'Query', artistApplications: Array<{ __typename?: 'ArtistApplication', id: number, username: string, email: string, walletAddress: string, profileImage?: string | null, twitter: string, status: string, numberOfApplications: number, hasArtwork: string, artworkLink?: string | null, question1: string, question2: string, question3: string }> };

export type SearchArtworksQueryVariables = Exact<{
  search: Scalars['String']['input'];
}>;


export type SearchArtworksQuery = { __typename?: 'Query', searchNfts: Array<{ __typename?: 'Nft', id: number, title: string, description: string, tokenId?: number | null, contractAddress: string, standard: string, platform: string, price: number, listingType: string, listedOn?: string | null, hiddenByOGCreator: boolean, status: boolean, imageUrl: string, assetType: string, width: number, height: number, isCompressed: boolean, ogCreator?: { __typename?: 'User', id: number, username: string, userType: string, walletAddress: string } | null, trades: Array<{ __typename?: 'Trade', id: number, price: number, amount: number }> }> };

export type GetRandomNftsQueryVariables = Exact<{
  quantity?: Scalars['Int']['input'];
}>;


export type GetRandomNftsQuery = { __typename?: 'Query', nfts: Array<{ __typename?: 'Nft', id: number, title: string, description: string, standard: string, platform: string, tokenId?: number | null, contractAddress: string, network: number, imageUrl: string, price: number, listingType: string, width: number, height: number, isCompressed: boolean, assetType: string, ogCreator?: { __typename?: 'User', id: number, username: string, userType: string, walletAddress: string } | null }> };

export type GetTradesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTradesQuery = { __typename?: 'Query', trades: Array<{ __typename?: 'Trade', id: number, nftId?: number | null, tokenId: number, contractAddress: string, sellerId?: number | null, sellerAddress: string, price: number, amount: number, transactionHash: string, sellerRevenue: number, commissions: number, ninfaFee: number, royaltyFee: number, isSecondary: string, ethToUsd: number, eurToUsd: number, platform: string, network: string, tradeType: string, createdAt: string, nft?: { __typename?: 'Nft', title: string } | null, seller?: { __typename?: 'User', username: string, email?: string | null, legacyId?: number | null, details?: { __typename?: 'UserDetails', firstName?: string | null, lastName?: string | null, type?: string | null, dob?: string | null, nationality?: string | null, region?: string | null, city?: string | null, street?: string | null, postalCode?: string | null, taxId?: string | null, vatNumber?: string | null, unicode?: string | null } | null } | null }> };

export type GetChartsDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetChartsDataQuery = { __typename?: 'Query', trades: Array<{ __typename?: 'Trade', price: number, ninfaFee: number, ethToUsd: number, amount: number, createdAt: string, network: string }>, users: Array<{ __typename?: 'User', userType: string, createdAt: string, approvedAt?: string | null }>, minted: Array<{ __typename?: 'Nft', createdAt: string, standard: string, network: number }>, artistApplications: Array<{ __typename?: 'ArtistApplication', createdAt: string }> };


export const UpdateUserTypeDocument = gql`
    mutation UpdateUserType($id: Int!, $userType: String!) {
  updateUserType(id: $id, userType: $userType)
}
    `;
export const ApproveApplicationDocument = gql`
    mutation ApproveApplication($id: Int!) {
  approveArtistApplication(id: $id)
}
    `;
export const RejectApplicationDocument = gql`
    mutation RejectApplication($id: Int!, $sendEmail: Boolean!) {
  rejectArtistApplication(id: $id, sendEmail: $sendEmail)
}
    `;
export const HideNftDocument = gql`
    mutation HideNft($id: Int!, $hidden: Boolean!, $type: String!) {
  hideNft(id: $id, hidden: $hidden, type: $type)
}
    `;
export const UpdateUserGenreDocument = gql`
    mutation updateUserGenre($id: Int!, $genre: String) {
  updateUser(id: $id, genre: $genre) {
    id
  }
}
    `;
export const SignInDocument = gql`
    query SignIn($walletAddress: String!) {
  user(walletAddress: $walletAddress) {
    userType
  }
}
    `;
export const SearchUsersDocument = gql`
    query SearchUsers($search: String!) {
  users(search: $search) {
    id
    walletAddress
    username
    email
    userType
    twitter
    genre
  }
}
    `;
export const GetArtistsDocument = gql`
    query GetArtists {
  users(userType: [USER_TYPE_ARTIST]) {
    id
    walletAddress
    username
    email
    userType
    twitter
    genre
  }
}
    `;
export const GetApplicationsDocument = gql`
    query GetApplications($search: String, $status: [ArtistApplicationStatus], $hasArtwork: Boolean, $limit: Int, $offset: Int, $orderBy: String) {
  artistApplications(
    search: $search
    status: $status
    hasArtwork: $hasArtwork
    limit: $limit
    offset: $offset
    orderBy: $orderBy
  ) {
    id
    username
    email
    walletAddress
    profileImage
    twitter
    status
    numberOfApplications
    hasArtwork
    artworkLink
    question1
    question2
    question3
  }
}
    `;
export const SearchArtworksDocument = gql`
    query SearchArtworks($search: String!) {
  searchNfts(search: $search) {
    id
    title
    description
    tokenId
    contractAddress
    standard
    platform
    price
    listingType
    listedOn
    hiddenByOGCreator
    status
    imageUrl
    assetType
    width
    height
    isCompressed
    ogCreator {
      id
      username
      userType
      walletAddress
    }
    trades {
      id
      price
      amount
    }
  }
}
    `;
export const GetRandomNftsDocument = gql`
    query GetRandomNfts($quantity: Int! = 1) {
  nfts(orderBy: "RAND()", limit: $quantity, listedOn: [PLATFORM_NINFA]) {
    id
    title
    description
    standard
    platform
    tokenId
    contractAddress
    network
    imageUrl
    price
    listingType
    width
    height
    isCompressed
    assetType
    ogCreator {
      id
      username
      userType
      walletAddress
    }
  }
}
    `;
export const GetTradesDocument = gql`
    query GetTrades {
  trades(orderBy: "createdAt DESC") {
    id
    nftId
    tokenId
    contractAddress
    nft {
      title
    }
    sellerId
    sellerAddress
    seller {
      username
      email
      legacyId
      details {
        firstName
        lastName
        type
        dob
        nationality
        region
        city
        street
        postalCode
        taxId
        vatNumber
        unicode
      }
    }
    price
    amount
    transactionHash
    sellerRevenue
    commissions
    ninfaFee
    royaltyFee
    isSecondary
    ethToUsd
    eurToUsd
    platform
    network
    tradeType
    createdAt
  }
}
    `;
export const GetChartsDataDocument = gql`
    query GetChartsData {
  trades(orderBy: "createdAt DESC", isVolume: true) {
    price
    ninfaFee
    ethToUsd
    amount
    createdAt
    network
  }
  users(
    orderBy: "createdAt DESC"
    userType: [USER_TYPE_COLLECTOR, USER_TYPE_ARTIST]
  ) {
    userType
    createdAt
    approvedAt
  }
  minted: nfts(orderBy: "createdAt", platform: [PLATFORM_NINFA]) {
    createdAt
    standard
    network
  }
  artistApplications(
    orderBy: "createdAt DESC"
    status: [ARTIST_APPLICATION_STATUS_PENDING, ARTIST_APPLICATION_STATUS_REJECTED]
  ) {
    createdAt
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    UpdateUserType(variables: UpdateUserTypeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateUserTypeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateUserTypeMutation>(UpdateUserTypeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateUserType', 'mutation');
    },
    ApproveApplication(variables: ApproveApplicationMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ApproveApplicationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ApproveApplicationMutation>(ApproveApplicationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ApproveApplication', 'mutation');
    },
    RejectApplication(variables: RejectApplicationMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RejectApplicationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RejectApplicationMutation>(RejectApplicationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RejectApplication', 'mutation');
    },
    HideNft(variables: HideNftMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<HideNftMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<HideNftMutation>(HideNftDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'HideNft', 'mutation');
    },
    updateUserGenre(variables: UpdateUserGenreMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateUserGenreMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateUserGenreMutation>(UpdateUserGenreDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateUserGenre', 'mutation');
    },
    SignIn(variables: SignInQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<SignInQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SignInQuery>(SignInDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SignIn', 'query');
    },
    SearchUsers(variables: SearchUsersQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<SearchUsersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SearchUsersQuery>(SearchUsersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SearchUsers', 'query');
    },
    GetArtists(variables?: GetArtistsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetArtistsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetArtistsQuery>(GetArtistsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetArtists', 'query');
    },
    GetApplications(variables?: GetApplicationsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetApplicationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetApplicationsQuery>(GetApplicationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetApplications', 'query');
    },
    SearchArtworks(variables: SearchArtworksQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<SearchArtworksQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SearchArtworksQuery>(SearchArtworksDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SearchArtworks', 'query');
    },
    GetRandomNfts(variables?: GetRandomNftsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetRandomNftsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetRandomNftsQuery>(GetRandomNftsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetRandomNfts', 'query');
    },
    GetTrades(variables?: GetTradesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetTradesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetTradesQuery>(GetTradesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetTrades', 'query');
    },
    GetChartsData(variables?: GetChartsDataQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetChartsDataQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetChartsDataQuery>(GetChartsDataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetChartsData', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;