import axios from "axios";
import {
  NewsletterArtwork,
  ArtworksNewsletterBody,
} from "./pages/Newsletter/generator/sommelierGenerator";

export const getOpenseaImageUrl = async (
  tokenId: number,
  contractAddress: string
) => {
  try {
    const url = `https://api.opensea.io/api/v2/chain/ethereum/contract/${contractAddress}/nfts/${tokenId}`;

    // https://api.opensea.io/api/v2/asset/${contractAddress}/${tokenId}/?include_orders=false`;
    const { data } = await axios.get<{
      nft: {
        image_url: string;
      };
    }>(url, {
      headers: {
        "X-API-KEY": "a993a5abe2fb4990a987504b0ff6f9c1",
      },
    });
    return data.nft.image_url;
  } catch (e) {
    console.log(e);
    return "";
  }
};

export const getVideoThumbnail = async (imageHash: string) => {
  const payload = JSON.stringify({
    imageHash,
  });
  const res = await axios.post<string>(
    "https://us-central1-ninfa-core.cloudfunctions.net/getVideoThumbnail",
    payload
  );
  return res.data;
};

export const getExchangeRateAPI = async (symbol = "ETHUSDT") => {
  const res = await axios.get<{
    symbol: string;
    price: string;
  }>(`https://www.binance.com/api/v3/ticker/price?symbol=${symbol}`);
  return Number(res.data.price);
};

export const sendArtworksNewsletter = async (
  body: ArtworksNewsletterBody,
  subject: string,
  to?: string[]
) => {
  try {
    const payload = JSON.stringify({
      newsletterType: "artworks",
      body,
      subject,
      to,
    });
    const res = await axios.post<string>(
      "https://us-central1-ninfa-core.cloudfunctions.net/newsletterSender",
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
        timeout: to === undefined ? 1000 : 60000,
      }
    );
    return res.data;
  } catch (e) {
    console.log(e);
    return "";
  }
};
